import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import axios from "axios";

const KnowledgeBase = () => {
  // const [activeTab, setActiveTab] = useState("all");
  const [scrapedData, setScrapedData] = useState([]); // State to store fetched data
  const [selectedResource, setSelectedResource] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [propertyType, setPropertyType] = useState("Property Type");
  const [city, setCity] = useState("City");
  const [country, setCountry] = useState("Country");

  // Extract unique property types, cities, and countries
  const propertyTypes = ["Property Type", ...new Set(scrapedData.map((item) => item.propertyType))];
  const cities = ["City", ...new Set(scrapedData.map((item) => item.city))];
  const countries = ["Country", ...new Set(scrapedData.map((item) => item.country))];

  // Fetch scraped data from the backend API
  useEffect(() => {
    const fetchScrapedData = async () => {
      try {
        // const response = await fetch("/api/knowledge/list"); // Replace with your API endpoint
        // const response = await axios.get("/api/knowledge/list");
        const response = await axios.get("/api/knowledgelist", {
          withCredentials: true,
        });
        console.log("response: ", response);
        // if (!response.ok) {
        //   throw new Error("Failed to fetch scraped data");
        // }
        console.log("response.data: ", response.data);
        // const data = await response.json();
        const data = await response.data;
        setScrapedData(data); // Update state with fetched data
      } catch (error) {
        console.error("Error fetching scraped data:", error);
      }
    };

    fetchScrapedData();
  }, []); // Run once on component mount

  useEffect(() => {
    let filtered = scrapedData;
    if (propertyType !== "Property Type") {
      filtered = filtered.filter((item) => item.propertyType === propertyType);
    }
    if (city !== "City") {
      filtered = filtered.filter((item) => item.city === city);
    }
    if (country !== "Country") {
      filtered = filtered.filter((item) => item.country === country);
    }
    setFilteredData(filtered);
  }, [propertyType, city, country, scrapedData]);

  // Handle delete button click
  const handleDeleteClick = (resource) => {
    setSelectedResource(resource);
    setShowConfirmation(true);
  };

  // Handle confirmation dialog
  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`/api/knowledge/del/${selectedResource._id}`);
      setScrapedData(scrapedData.filter((r) => r._id !== selectedResource._id));
      setShowConfirmation(false);
    } catch (error) {
      console.error("Error deleting resource:", error);
    }
  };

  // Cancel deletion
  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  return (
    <main className="flex">
      <Sidebar title="Knowledge Base" />
      <div className="main-container">
        <Header />
        <div className="knowledgebase">
          {/* Header */}
          <div className="knowledgebase__header">
            <button className="knowledgebase__header-button active">Knowledge Base</button>
            {/* <button className="knowledgebase__header-button">Drafts</button> */}
            <button className="knowledgebase__header-button">
              <NavLink activeclassname="active" to="/knowledgeadd">
                Add Knowledge
              </NavLink>
            </button>
          </div>

          {/* Tabs Navigation */}
          {/* <div className="knowledgebase__tabs">
            <button
              className={
                activeTab === "all"
                  ? "knowledgebase__tab active"
                  : "knowledgebase__tab"
              }
              onClick={() => setActiveTab("all")}
            >
              All Data
            </button>
            <button
              className={
                activeTab === "pending"
                  ? "knowledgebase__tab active"
                  : "knowledgebase__tab"
              }
              onClick={() => setActiveTab("pending")}
            >
              Pending Approval
            </button>
          </div> */}

          {/* select menu */}
          {/* <div className="knowledgeadd__select-container">
            <select className="knowledgeadd__custom-select" onChange={(e) => setPropertyType(e.target.value)}>
              {propertyTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>

            <select className="knowledgeadd__custom-select" onChange={(e) => setCity(e.target.value)}>
              {cities.map((c) => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </select>

            <select className="knowledgeadd__custom-select" onChange={(e) => setCountry(e.target.value)}>
              {countries.map((c) => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </select>
          </div> */}
          {/* form fields */}

          {/* Cards Section */}
          <div className="knowledgebase__cards">
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <div className="knowledgebasev2__property-card">
                  {/* Top Section - Information */}
                  <div className="knowledgebasev2__top-section">
                    {/* Left Side - Images */}
                    <div className="knowledgebasev2__left-section">
                      {/* <div className="knowledgebasev2__image-container"> */}
                      <div className="knowledgebase__image-container">
                        <NavLink
                          to={`/knowledgeedit/${item._id}`} // Replace dataID with the actual ID you want to pass
                          title="View"
                        >
                          <img
                            className="knowledgebase__main-image"
                            src={item.images[0]} // Use the first image as the main image
                            alt={item.title}
                          />
                        </NavLink>
                        <div className="knowledgebase__thumbnails">
                          {item.images.slice(1, 4).map((thumbnail, idx) => (
                            <img key={idx} className="knowledgebase__thumbnail" src={thumbnail} alt={`thumbnail ${idx + 1}`} />
                          ))}
                        </div>
                      </div>
                      <a href="#" className="knowledgebase__view-more">
                        View more images
                      </a>
                    </div>

                    {/* Middle - Description */}
                    <div className="knowledgebasev2__middle-section">
                      <h2 className="knowledgebase__title">{item.title}</h2>
                      <p className="knowledgebase__subtitle">{item.projectType}</p>
                      <p className="knowledgebase__description">{item.description}</p>
                      <div className="knowledgebasev2__details-grid">
                        <div>
                          <p className="knowledgebasev2__label">Date of Completion:</p>
                          <p className="knowledgebasev2__value">Q4 2026</p>
                        </div>
                        <div>
                          <p className="knowledgebasev2__label">Total Units:</p>
                          <p className="knowledgebasev2__value">50</p>
                        </div>
                      </div>
                    </div>

                    {/* Right Side - Price, Property ID, Logo */}
                    <div className="knowledgebasev2__right-section">
                      <div className="knowledgebasev2__price-section">
                        <p className="knowledgebasev2__price">
                          {item.currency} {item.price}
                        </p>
                        <p className="knowledgebasev2__property-id">Property ID: 21224</p>
                      </div>
                      <div className="knowledgebasev2__logo-section">
                        <img src={item.developerLogo} alt="Developer Logo" className="knowledgebasev2__logo" />
                      </div>
                    </div>
                  </div>

                  {/* Bottom Section - Actions */}
                  <div className="knowledgebasev2__bottom-section">
                    <div className="knowledgebasev2__bottom-actions">
                      <div className="knowledgebasev2__icons">
                        <a href="#" className="knowledgebasev2__icon-button" title="Share">
                          <img src="assets/images/share.svg" alt="Share" />
                        </a>
                        <NavLink to={`/knowledgeedit/${item._id}`} className="knowledgebasev2__icon-button" title="Edit">
                          <img src="assets/images/edit.svg" alt="Edit" />
                        </NavLink>
                        <a href="#" className="knowledgebasev2__icon-button" title="Delete" onClick={() => handleDeleteClick(item)}>
                          <img src="assets/images/delete.svg" alt="Delete" />
                        </a>
                      </div>
                      <button className="knowledgebasev2__post-button">Post on Marketplace</button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No data available.</p>
            )}
          </div>
        </div>
        {showConfirmation && (
          <div className="knowledgebase__confirmation-dialog">
            <p>Are you sure you want to delete this property?</p>
            <button onClick={handleConfirmDelete}>Yes</button>
            <button onClick={handleCancelDelete}>No</button>
          </div>
        )}
      </div>
    </main>
  );
};

export default KnowledgeBase;
