import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import axios from "axios";

const KnowledgeUpload = () => {
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    const validFiles = newFiles.filter(
      (file) =>
        (file.type.startsWith("image/") || file.type === "application/pdf") &&
        file.size <= 2 * 1024 * 1024
    );

    if (validFiles.length !== newFiles.length) {
      setError("Some files are invalid (must be images or PDFs and <2MB).");
    } else {
      setError(null);
    }

    setFiles([
      ...files,
      ...validFiles.map((file) => ({
        file,
        preview: file.type.startsWith("image/")
          ? URL.createObjectURL(file)
          : null,
      })),
    ]);
  };

  const handleRemove = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleUpload = async () => {
    const formData = new FormData();
    files.forEach((f) => formData.append("images", f.file));
    formData.append("title", "Green Village");
    formData.append("propertyType", "Family House");
    formData.append("description", "Home Sweet Home");
    formData.append("currency", "USD");
    formData.append("price", "45000");
    console.log(formData);
    try {
      const response = await axios.post("/api/knowledge/test", formData);
      setMessage(response.data.message);
      setFiles([]);
    } catch (error) {
      setError(error.response?.data.error || "Upload failed");
    }
  };

  return (
    <main className="flex">
      <Sidebar title="Knowledge Base" />
      <div className="main-container">
        <Header />
        <div className="knowledgeadd">
          {/* Header */}
          <div className="knowledgebase__header">
            <button className="knowledgebase__header-button">
              <NavLink activeclassname="active" to="/knowledgebase">
                Knowledge Base
              </NavLink>
            </button>
            <button className="knowledgebase__header-button active">
              Add Knowledge
            </button>
          </div>
          {/* <KnowledgeDetail isNew="true" /> */}
          <div className="knowledgeupload__container">
            {message && (
              <div className="knowledgeupload__dialog success">{message}</div>
            )}
            {error && (
              <div className="knowledgeupload__dialog error">{error}</div>
            )}

            <div className="knowledgeupload__file-input-wrapper">
              <input
                id="fileInput"
                type="file"
                multiple
                onChange={handleFileChange}
                hidden
              />
            </div>

            <div className="knowledgeupload__preview-container">
              {files.map((f, index) => (
                <div key={index} className="knowledgeupload__file-wrapper">
                  {f.preview ? (
                    <img
                      src={f.preview}
                      alt="preview"
                      className="knowledgeupload__preview"
                    />
                  ) : (
                    <span>{f.file.name}</span>
                  )}
                  <span
                    className="knowledgeupload__delete-icon"
                    onClick={() => handleRemove(index)}
                  >
                    ❌
                  </span>
                </div>
              ))}
              {files.length < 5 && (
                <label
                  htmlFor="fileInput"
                  className="knowledgeupload__add-files-icon"
                >
                  ➕
                </label>
              )}
            </div>
            <button
              className="knowledgeupload__button"
              onClick={handleUpload}
              disabled={files.length === 0}
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default KnowledgeUpload;
