import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// import HelpModal from "./HelpModel";

const Header = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    event.preventDefault();
    // Redirect to search-result page with the search query
    window.location.href = `/search-result?query=${searchQuery}`;
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false); // State for the modal

  const dropdownRef = useRef(null); // Ref for the dropdown element

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // const openModal = () => {
  //   setIsModalOpen(true);
  // };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Close dropdown if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="main-container__header">
      <div className="main-container__header-nav flex align-center">
        <form onSubmit={handleSearch}>
          <input
            type="search"
            placeholder="Name, Contact, Email"
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
          />
        </form>
        {/* <a href="#" role="button" onClick={openModal}> */}
        <a href="#" role="button">
          <img src="/assets/icons/icon-help.svg" alt="icon-help" />
        </a>
        {/* {isModalOpen && <HelpModal onClose={closeModal} />} */}
        <a
          href="#"
          role="button"
          style={{ position: "relative" }}
          onClick={toggleDropdown}
          ref={dropdownRef} // Assign ref to the dropdown element
        >
          <img
            src="/assets/icons/icon-notification.svg"
            alt="icon-notification"
          />
          {isDropdownOpen && (
            <div className="notification-dropdown">
              {/* Dropdown content goes here */}
              <h3>Notifications</h3>
              <p>Notification 1</p>
              <p>Notification 2</p>
              <p>Notification 3</p>
            </div>
          )}
        </a>
        <div className="profile-pic">
          <Link to="/setting">
            <img src="/assets/images/profile-pic.png" alt="profile" />
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
