import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom"; // For navigation
import axios from "axios";

const KnowledgeDetail = ({ isNew }) => {
  const navigate = useNavigate(); // For navigating to the main menu
  const { dataid } = useParams();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [imgArr, setImgArr] = useState("");

  const [imageFiles, setImageFiles] = useState([]);
  const [imagePreviewUrls, setImagePreviewUrls] = useState([]);
  const [brochureFile, setBrochureFile] = useState("");
  const [masterplanFile, setMasterplanFile] = useState("");
  const [developerLogoFile, setDeveloperLogoFile] = useState("");

  // const [amenities, setAmenities] = useState(["Swimming Pool", "Gym", "Clubhouse"]);
  const [amenities, setAmenities] = useState([]);
  const [inputAmenity, setInputAmenity] = useState("");
  const [listingType, setListingType] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [city, setCity] = useState("");

  const [selectListingType, setSelectListingType] = useState(["Off-Plan", "Ready", "Secondary", "Rental"]);
  const [selectPropertyType, setSelectPropertyType] = useState(["Apartment", "Villa", "Townhouse", "Penthouse", "Other"]);
  const [selectCity, setSelectCity] = useState([
    "Abu Dhabi",
    "Dubai",
    "Ras Al Khaimah",
    "Jeddah",
    "Riyadh",
    "Doha",
    "Muscat",
    "Istambul",
    "Bodrum",
    "Alanya",
    "Casablanca",
    "Marrakech",
    "Agadir",
    "Oran",
  ]);

  const [units, setUnits] = useState([
    { id: 1, type: "Sapphire", bedrooms: 2, bathrooms: 3, area: 3335, plan: "2bhk_Sapphire_fp.jpg", price: "From 8.5M AED", quantity: 17 },
    { id: 2, type: "Emerald", bedrooms: 2, bathrooms: 3, area: 3337, plan: "2bhk_Emerald_fp.jpg", price: "From 8.5M AED", quantity: 8 },
    { id: 3, type: "Ruby", bedrooms: 3, bathrooms: 4, area: 3299, plan: "3bhk_Ruby_fp.jpg", price: "From 14M AED", quantity: 8 },
  ]);

  const [developerDetails, setdeveloperDetails] = useState([
    { id: 1, key: "Construction Status*", value: "Under Construction" },
    { id: 2, key: "Date of Completion*", value: "Q4 2026" },
  ]);

  const [keyInfo, setKeyInfo] = useState([
    { id: 1, key: "Payment Plan", value: "70% During construction & 30% On handover" },
    { id: 2, key: "Govermment Fees", value: "4%" },
  ]);

  const [projectTimeline, setProjectTimeline] = useState([
    { id: 1, title: "Project Annoucement", description: "-", status: "Done" },
    { id: 2, title: "Project Kickoff", description: "To be discuss", status: "Pending" },
  ]);

  const handleAmenityKeyDown = (e) => {
    if (e.key === "Enter" && inputAmenity.trim()) {
      e.preventDefault();
      setAmenities([...amenities, inputAmenity.trim()]);
      setInputAmenity("");
    }
    // console.log("handleAmenityKeyDown amenities:", amenities);
  };

  const removeAmenity = (index) => {
    setAmenities(amenities.filter((_, i) => i !== index));
  };

  const [sections, setSections] = useState({
    unitTypes: false,
    developerDetails: false,
    keyInfo: false,
    projectTimeline: false,
  });

  const toggleSection = (section) => {
    setSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  // Maximum file size (1MB)
  const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB in bytes

  // Allowed MIME types
  // const ALLOWED_IMAGE_TYPES = ["image/jpeg", "image/png", "image/gif"];
  const ALLOWED_IMAGE_TYPES = "image/";
  const ALLOWED_BROCHURE_TYPES = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];

  useEffect(() => {
    const fetchKnowledgeDetail = async () => {
      try {
        console.log("useEffect dataid: ", dataid);
        const response = await axios.get(`/api/knowledgedetail/${dataid}`);
        console.log("Knowledge Detail:", response.data);
        setData(response.data);
        console.log("response.data._id:", response.data._id);
        setImagePreviewUrls(response.data.images);
        setImgArr(Array.isArray(response.data.images) ? response.data.images.join("\n") : "");
        setAmenities(response.data.amenities);
        // console.log("response.data.amenities:", response.data.amenities);
        // Handle the response data as needed
      } catch (error) {
        setError(error.message);
      }
    };

    const emptyData = async () => {
      setData({
        title: "",
        projectType: "",
        description: "",
        city: "",
        country: "",
        currency: "",
        price: "",
        amenities: [],
        webUrl: "",
        images: [],
        masterPlan: "",
        brochure: "",
        propertyDLDNumber: "",
        developerName: "",
        developerLogo: "",
        videoUrl: "",
        tourUrl: "",
        listingType: "",
        propertyType: "",
      });
    };

    if (isNew === "true") {
      emptyData();
    } else {
      if (dataid) {
        fetchKnowledgeDetail();
      }
    }
  }, [dataid]); // Dependency array includes dataid

  if (error) {
    return <p style={{ color: "red" }}>{error}</p>;
  }

  if (!data) {
    return <p>Loading...</p>;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  // ================= new functions 20250219 =================

  // Handle image file selection with validation
  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    // Validate each file
    const validFiles = [];
    const newPreviewUrls = [];

    selectedFiles.forEach((file) => {
      // if (!ALLOWED_IMAGE_TYPES.includes(file.type)) {
      if (!file.type.startsWith(ALLOWED_IMAGE_TYPES)) {
        alert(`File "${file.name}" is not a valid image type.`);
      } else if (file.size > MAX_FILE_SIZE) {
        alert(`File "${file.name}" exceeds the maximum size of 2MB.`);
      } else {
        validFiles.push(file);
        newPreviewUrls.push(URL.createObjectURL(file));
      }
    });

    // Update state with valid files
    setImageFiles((prevFiles) => [...prevFiles, ...validFiles]);
    setImagePreviewUrls((prevUrls) => [...prevUrls, ...newPreviewUrls]);
  };

  // Handle brochure file selection with validation
  const handleBrochureChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    // Validate each file
    const validFiles = [];

    selectedFiles.forEach((file) => {
      // if (file.size > MAX_FILE_SIZE) {
      //   alert(`File "${file.name}" exceeds the maximum size of 1MB.`);
      // } else if (!ALLOWED_BROCHURE_TYPES.includes(file.type)) {
      //   alert(`File "${file.name}" is not a valid document type.`);
      // } else {
      if (!file.type.startsWith(ALLOWED_IMAGE_TYPES)) {
        alert(`File "${file.name}" is not a valid image type.`);
      } else if (file.size > MAX_FILE_SIZE) {
        alert(`File "${file.name}" exceeds the maximum size of 2MB.`);
      } else {
        // validFiles.push(file);
        setBrochureFile(file);
      }
    });

    // Update state with valid files
    // setBrochureFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  // Handle master plan file selection with validation
  const handleMasterplanChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    selectedFiles.forEach((file) => {
      if (!file.type.startsWith(ALLOWED_IMAGE_TYPES)) {
        alert(`File "${file.name}" is not a valid image type.`);
      } else if (file.size > MAX_FILE_SIZE) {
        alert(`File "${file.name}" exceeds the maximum size of 2MB.`);
      } else {
        setMasterplanFile(file);
      }
    });
  };

  // Handle master plan file selection with validation
  const handleDeveloperLogoChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    selectedFiles.forEach((file) => {
      if (!file.type.startsWith(ALLOWED_IMAGE_TYPES)) {
        alert(`File "${file.name}" is not a valid image type.`);
      } else if (file.size > MAX_FILE_SIZE) {
        alert(`File "${file.name}" exceeds the maximum size of 2MB.`);
      } else {
        setDeveloperLogoFile(file);
      }
    });
  };

  // Remove an image preview
  const handleRemoveImage = (index) => {
    const updatedImageFiles = imageFiles.filter((_, i) => i !== index);
    const updatedPreviewUrls = imagePreviewUrls.filter((_, i) => i !== index);

    setImageFiles(updatedImageFiles);
    setImagePreviewUrls(updatedPreviewUrls);
  };

  // Remove brochure file
  const handleRemoveBrochure = () => {
    setBrochureFile("");
  };

  const handleRemoveMasterplan = () => {
    setMasterplanFile("");
  };

  const handleRemoveDeveloperLogo = () => {
    setDeveloperLogoFile("");
  };

  // Upload both images and brochures
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    // Append data fields to FormData
    // Object.keys(data).forEach((key) => {
    //   formData.append(key, data[key]);
    //   console.log(`key: ${key}, value: ${data[key]}`);
    // });

    Object.keys(data).forEach((key) => {
      if (key !== "amenities") {
        formData.append(key, data[key]);
        console.log(`key: ${key}, value: ${data[key]}`);
      }
    });

    amenities.forEach((tag) => formData.append("amenities", tag));

    // Append image files
    imageFiles.forEach((file) => {
      // formData.append("files", file);
      formData.append("images", file);
    });

    // Append brochure file
    formData.append("brochure", brochureFile);

    // Append masterPlan file
    formData.append("masterPlan", masterplanFile);

    // Append developerLogo file
    formData.append("developerLogo", developerLogoFile);

    // Append dropdown menu
    // formData.append("listingType", listingType);
    // formData.append("propertyType", propertyType);
    // formData.append("city", city);

    // Log FormData contents
    // for (let pair of formData.entries()) {
    //   console.log(`[formData] key: ${pair[0]}, value: ${pair[1]}`);
    // }

    try {
      if (isNew === "true") {
        const response = await axios.post("/api/knowledge/new", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // if (response.data.message === "Property save successfully") {
        //   setShowSuccessDialog(true); // Show success dialog
        //   setShowErrorDialog(false); // Hide error dialog
        //   console.log("setShowSuccessDialog(true), setShowErrorDialog(false)");
        // }
      } else {
        const response = await axios.post("/api/knowledge/update", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // if (response.data.message === "Property update successfully") {
        //   setShowSuccessDialog(true); // Show success dialog
        //   setShowErrorDialog(false); // Hide error dialog
        // }
      }

      // const response = await axios.post("/api/knowledge/new", formData, {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // });

      // alert("Files uploaded successfully!");
      // console.log("Uploaded Image URLs:", response.data.images);
      // console.log("Uploaded Brochure URLs:", response.data.brochures);
      setShowSuccessDialog(true); // Show success dialog
      setShowErrorDialog(false); // Hide error dialog
    } catch (error) {
      console.error("Error uploading files:", error);
      // alert("Error uploading files");
      setErrorMessage(error.response?.data?.message || "An unexpected error occurred.");
      setShowErrorDialog(true); // Show error dialog
    } finally {
      setLoading(false);
    }
  };

  const addUnitRow = () => {
    setUnits([...units, { id: Date.now(), type: "", bedrooms: "", bathrooms: "", area: "", plan: "", price: "", quantity: "" }]);
  };

  const removeUnitRow = (id) => {
    setUnits(units.filter((unit) => unit.id !== id));
  };

  const handleUnitChange = (id, field, value) => {
    setUnits(units.map((unit) => (unit.id === id ? { ...unit, [field]: value } : unit)));
  };

  const addDeveloperDetailsRow = () => {
    setdeveloperDetails([...developerDetails, { id: Date.now(), key: "", value: "" }]);
  };

  const removeDeveloperDetailsRow = (id) => {
    setdeveloperDetails(developerDetails.filter((data) => data.id !== id));
  };

  const handleDeveloperDetailsChange = (id, field, value) => {
    setdeveloperDetails(developerDetails.map((data) => (data.id === id ? { ...data, [field]: value } : data)));
  };

  const addKeyInfoRow = () => {
    setKeyInfo([...keyInfo, { id: Date.now(), key: "", value: "" }]);
  };

  const removeKeyInfoRow = (id) => {
    setKeyInfo(keyInfo.filter((data) => data.id !== id));
  };

  const handleKeyInfoChange = (id, field, value) => {
    setKeyInfo(keyInfo.map((data) => (data.id === id ? { ...data, [field]: value } : data)));
  };

  const addProjectTimelineRow = () => {
    setKeyInfo([...projectTimeline, { id: Date.now(), key: "", value: "" }]);
  };

  const removeProjectTimelineRow = (id) => {
    setKeyInfo(projectTimeline.filter((data) => data.id !== id));
  };

  const handleProjectTimelineChange = (id, field, value) => {
    setKeyInfo(projectTimeline.map((data) => (data.id === id ? { ...data, [field]: value } : data)));
  };

  return (
    <div className="knowledgeadd__scraped-data">
      {/* Error Dialog */}
      {showErrorDialog && (
        <div className="knowledgeadd__error-dialog">
          <p>{errorMessage}</p>
          <button onClick={() => setShowErrorDialog(false)}>OK</button>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        {data && (
          <div>
            <div key={data._id} className="scraped-data">
              {/* Header Bar*/}
              <div className="knowledgeadd__headerbar">Project Details</div>
              {/* Image Previews */}
              <div className="knowledgeadd__scraped-img">
                {imagePreviewUrls.map((url, index) => (
                  <div key={index} className="knowledgeupload__file-wrapper">
                    <img src={url} alt="preview" className="knowledgeupload__preview" />
                    {isNew === "true" && (
                      <span className="knowledgeupload__delete-icon" onClick={() => handleRemoveImage(index)}>
                        ❌
                      </span>
                    )}
                  </div>
                ))}
                {imagePreviewUrls.length < 5 && isNew === "true" && (
                  <label htmlFor="fileInput">
                    <img src="/assets/icons/icon-upload.svg" alt="Add Image" style={{ cursor: "pointer" }} width="200px" />
                  </label>
                )}
                <input id="fileInput" type="file" multiple onChange={handleImageChange} hidden />
              </div>
              {/* select menu */}
              <div className="knowledgeadd__select-container">
                {/* <select className="knowledgeadd__custom-select" onChange={(e) => setListingType(e.target.value)} value={data.listingType}> */}
                <select name="listingType" className="knowledgeadd__custom-select" onChange={handleChange} value={data.listingType}>
                  <option key="" value="">
                    Listing Type
                  </option>
                  {selectListingType.map((val) => (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
                {/* <select className="knowledgeadd__custom-select" onChange={(e) => setPropertyType(e.target.value)} value={data.propertyType}> */}
                <select name="propertyType" className="knowledgeadd__custom-select" onChange={handleChange} value={data.propertyType}>
                  <option key="" value="">
                    Property Type
                  </option>
                  {selectPropertyType.map((val) => (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
                {/* <select className="knowledgeadd__custom-select" onChange={(e) => setCity(e.target.value)} value={data.city}> */}
                <select name="city" className="knowledgeadd__custom-select" onChange={handleChange} value={data.city}>
                  <option key="" value="">
                    Select City
                  </option>
                  {selectCity.map((val) => (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
              {/* form fields */}
              <div className="knowledgeadd__form-fields">
                <label>Project Title*</label>
                <input type="text" name="title" value={data.title} onChange={handleChange} />
                <label>Project Type*</label>
                <input type="text" name="projectType" value={data.projectType} onChange={handleChange} />
                <label className="knowledgeadd__textarea-label">Project Description*</label>
                <textarea name="description" className="knowledgeadd__textarea-input" value={data.description} onChange={handleChange} />
                <label>Upload Masterplan</label>
                <div className="knowledgeadd__file-upload">
                  <label className="knowledgeadd__file-label">
                    {isNew === "true" ? (
                      <>
                        {masterplanFile.name}
                        {masterplanFile && (
                          <span onClick={handleRemoveMasterplan} className="knowledgeadd__file-delete-icon">
                            ❌
                          </span>
                        )}
                      </>
                    ) : (
                      data.masterPlan
                    )}
                  </label>
                  {isNew === "true" && (
                    <label htmlFor="masterPlan">
                      <img className="knowledgeadd__file-upload-icon" src="/assets/icons/icon-upload.svg" alt="Add Image" />
                    </label>
                  )}
                </div>
                <input id="masterPlan" type="file" onChange={handleMasterplanChange} hidden />
                <label>Upload Property Brochure</label>
                <div className="knowledgeadd__file-upload">
                  <label className="knowledgeadd__file-label">
                    {isNew === "true" ? (
                      <>
                        {brochureFile.name}
                        {brochureFile && (
                          <span onClick={handleRemoveBrochure} className="knowledgeadd__file-delete-icon">
                            ❌
                          </span>
                        )}
                      </>
                    ) : (
                      data.brochure
                    )}
                  </label>
                  {isNew === "true" && (
                    <label htmlFor="brochure">
                      <img className="knowledgeadd__file-upload-icon" src="/assets/icons/icon-upload.svg" alt="Add Image" />
                    </label>
                  )}
                </div>
                <input id="brochure" type="file" onChange={handleBrochureChange} hidden />
                {/* <label className="knowledgeadd__textarea-label">Amenities</label>
                <textarea name="amenities" className="knowledgeadd__textarea-input" value={data.amenities} onChange={handleChange} /> */}
                <label className="knowledgeadd__textarea-label">Amenities Tag</label>
                <div className="knowledgeadd__input-tag">
                  <input
                    type="text"
                    placeholder="Enter Amenities"
                    value={inputAmenity}
                    onChange={(e) => setInputAmenity(e.target.value)}
                    onKeyDown={handleAmenityKeyDown}
                    className="knowledgeadd__box-tag"
                  />
                  <div className="knowledgeadd__tag-container">
                    {/* {amenities.map((amenity, index) => ( */}
                    {amenities.map((amenity, index) => (
                      <div key={index} className="knowledgeadd__tag">
                        {amenity}
                        <span className="knowledgeadd__remove-tagbtn" onClick={() => removeAmenity(index)}>
                          x
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                {/* <label>City</label>
                <input type="text" name="city" value={data.city} onChange={handleChange} />
                <label>Country</label>
                <input type="text" name="country" value={data.country} onChange={handleChange} /> */}
                <label>Starting Price*</label>
                <input type="text" name="price" value={data.price} onChange={handleChange} />
                <label>Currency*</label>
                <input type="text" name="currency" value={data.currency} onChange={handleChange} />
                <label>Property DLD Number</label>
                <input type="text" name="propertyDLDNumber" value={data.propertyDLDNumber} onChange={handleChange} />
                <label>Developer Logo</label>
                <div className="knowledgeadd__file-upload">
                  <label className="knowledgeadd__file-label">
                    {isNew === "true" ? (
                      <>
                        {developerLogoFile.name}
                        {developerLogoFile && (
                          <span onClick={handleRemoveDeveloperLogo} className="knowledgeadd__file-delete-icon">
                            ❌
                          </span>
                        )}
                      </>
                    ) : (
                      data.developerLogo
                    )}
                  </label>
                  {isNew === "true" && (
                    <label htmlFor="developerLogo">
                      <img className="knowledgeadd__file-upload-icon" src="/assets/icons/icon-upload.svg" alt="Add Image" />
                    </label>
                  )}
                </div>
                <input id="developerLogo" type="file" onChange={handleDeveloperLogoChange} hidden />
                <label>Project Website</label>
                <input type="text" name="webUrl" value={data.webUrl} onChange={handleChange} />
                <label>Video URL</label>
                <input type="text" name="videoUrl" value={data.videoUrl} onChange={handleChange} />
                <label>Virtual Tour / 360 View URL</label>
                <input type="text" name="tourUrl" value={data.tourUrl} onChange={handleChange} />
                {/* <label label className="knowledgeadd__textarea-label">
                  Images (one URL per line):
                </label>
                <textarea
                  className="knowledgeadd__textarea-input-img"
                  name="images"
                  value={imgArr}
                  // value={Array.isArray(data.images) ? data.images.join("\n") : ""}
                  onChange={handleImageUrlsChange}
                  placeholder="Enter image URLs, one per line"
                /> */}
              </div>

              {/* Collapsible Sections */}
              <div className="knowledgeadd__collapsible-group">
                {/* Unit Types */}
                <div className="knowledgeadd__collapsible">
                  <div className={`knowledgeadd__section-header ${sections.unitTypes ? "expanded" : ""}`} onClick={() => toggleSection("unitTypes")}>
                    <span>Unit Types</span>
                    <span>{sections.unitTypes ? "-" : "+"}</span>
                  </div>
                  {sections.unitTypes && (
                    <div className="knowledgeadd__table-container">
                      <table>
                        <thead>
                          <tr>
                            <th>Layout Type</th>
                            <th>#Bedrooms</th>
                            <th>#Bathrooms</th>
                            <th>Area in Sq.ft.</th>
                            <th>Floors plan</th>
                            <th>Price</th>
                            <th>Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {units.map((unit) => (
                            <tr key={unit.id}>
                              <td className="knowledgeadd__layout-type">
                                <button type="button" className="knowledgeadd__delete-btn" onClick={() => removeUnitRow(unit.id)}>
                                  ×
                                </button>
                                {/* {editingCell?.id === unit.id && editingCell?.field === "type" ? ( */}
                                <input
                                  type="text"
                                  value={unit.type}
                                  onChange={(e) => handleUnitChange(unit.id, "type", e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault(); // Mencegah default enter
                                      e.target.blur(); // Menghilangkan fokus dari input
                                    }
                                  }}
                                  className="knowledgeadd__input-text"
                                />
                                {/* ) : (
                                  <span onClick={() => tableHandleEdit(unit.id, "type")}>{unit.type || "Click to edit"}</span>
                                )} */}
                              </td>

                              <td>
                                <input
                                  type="number"
                                  className="knowledgeadd__input-text"
                                  value={unit.bedrooms}
                                  onChange={(e) => handleUnitChange(unit.id, "bedrooms", e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault(); // Mencegah default enter
                                      e.target.blur(); // Menghilangkan fokus dari input
                                    }
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="knowledgeadd__input-text"
                                  value={unit.bathrooms}
                                  onChange={(e) => handleUnitChange(unit.id, "bathrooms", e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault(); // Mencegah default enter
                                      e.target.blur(); // Menghilangkan fokus dari input
                                    }
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="knowledgeadd__input-text"
                                  value={unit.area}
                                  onChange={(e) => handleUnitChange(unit.id, "area", e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault(); // Mencegah default enter
                                      e.target.blur(); // Menghilangkan fokus dari input
                                    }
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="knowledgeadd__input-text"
                                  value={unit.plan}
                                  onChange={(e) => handleUnitChange(unit.id, "plan", e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault(); // Mencegah default enter
                                      e.target.blur(); // Menghilangkan fokus dari input
                                    }
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="knowledgeadd__input-text"
                                  value={unit.price}
                                  onChange={(e) => handleUnitChange(unit.id, "price", e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault(); // Mencegah default enter
                                      e.target.blur(); // Menghilangkan fokus dari input
                                    }
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  className="knowledgeadd__input-text"
                                  value={unit.quantity}
                                  onChange={(e) => handleUnitChange(unit.id, "quantity", e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault(); // Mencegah default enter
                                      e.target.blur(); // Menghilangkan fokus dari input
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="knowledgeadd__bottom-bar">
                        <button type="button" className="knowledgeadd__add-btn" onClick={addUnitRow}>
                          +
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                {/* Developer Details */}
                <div className="knowledgeadd__collapsible">
                  <div
                    className={`knowledgeadd__section-header ${sections.developerDetails ? "expanded" : ""}`}
                    onClick={() => toggleSection("developerDetails")}
                  >
                    <span>Developer Details</span>
                    <span>{sections.developerDetails ? "-" : "+"}</span>
                  </div>
                  {sections.developerDetails && (
                    <div className="knowledgeadd__table-container">
                      <table>
                        <tbody>
                          {developerDetails.map((val) => (
                            <tr key={val.id}>
                              <td className="knowledgeadd__layout-type">
                                <button type="button" className="knowledgeadd__delete-btn" onClick={() => removeDeveloperDetailsRow(val.id)}>
                                  ×
                                </button>
                                {/* {editingCell?.id === unit.id && editingCell?.field === "type" ? ( */}
                                <input
                                  type="text"
                                  value={val.key}
                                  onChange={(e) => handleDeveloperDetailsChange(val.id, "key", e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault(); // Mencegah default enter
                                      e.target.blur(); // Menghilangkan fokus dari input
                                    }
                                  }}
                                  className="knowledgeadd__input-text"
                                />
                                {/* ) : (
                                  <span onClick={() => tableHandleEdit(unit.id, "type")}>{unit.type || "Click to edit"}</span>
                                )} */}
                              </td>

                              <td style={{ width: "70%" }}>
                                <input
                                  type="text"
                                  className="knowledgeadd__input-text"
                                  value={val.value}
                                  onChange={(e) => handleDeveloperDetailsChange(val.id, "value", e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault(); // Mencegah default enter
                                      e.target.blur(); // Menghilangkan fokus dari input
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="knowledgeadd__bottom-bar">
                        <button type="button" className="knowledgeadd__add-btn" onClick={addDeveloperDetailsRow}>
                          +
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                {/* Key Information */}
                <div className="knowledgeadd__collapsible">
                  <div className={`knowledgeadd__section-header ${sections.keyInfo ? "expanded" : ""}`} onClick={() => toggleSection("keyInfo")}>
                    <span>Key Information</span>
                    <span>{sections.keyInfo ? "-" : "+"}</span>
                  </div>
                  {sections.keyInfo && (
                    <div className="knowledgeadd__table-container">
                      <table>
                        <tbody>
                          {keyInfo.map((val) => (
                            <tr key={val.id}>
                              <td className="knowledgeadd__layout-type">
                                <button className="knowledgeadd__delete-btn" onClick={() => removeKeyInfoRow(val.id)}>
                                  ×
                                </button>
                                {/* {editingCell?.id === unit.id && editingCell?.field === "type" ? ( */}
                                <input
                                  type="text"
                                  value={val.key}
                                  onChange={(e) => handleKeyInfoChange(val.id, "key", e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault(); // Mencegah default enter
                                      e.target.blur(); // Menghilangkan fokus dari input
                                    }
                                  }}
                                  className="knowledgeadd__input-text"
                                />
                                {/* ) : (
                                  <span onClick={() => tableHandleEdit(unit.id, "type")}>{unit.type || "Click to edit"}</span>
                                )} */}
                              </td>

                              <td style={{ width: "70%" }}>
                                <input
                                  type="text"
                                  className="knowledgeadd__input-text"
                                  value={val.value}
                                  onChange={(e) => handleKeyInfoChange(val.id, "value", e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault(); // Mencegah default enter
                                      e.target.blur(); // Menghilangkan fokus dari input
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="knowledgeadd__bottom-bar">
                        <button type="button" className="knowledgeadd__add-btn" onClick={addKeyInfoRow}>
                          +
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                {/* Project Timeline */}
                <div className="knowledgeadd__collapsible">
                  <div
                    className={`knowledgeadd__section-header ${sections.projectTimeline ? "expanded" : ""}`}
                    onClick={() => toggleSection("projectTimeline")}
                  >
                    <span>Project Timeline</span>
                    <span>{sections.projectTimeline ? "-" : "+"}</span>
                  </div>
                  {sections.projectTimeline && (
                    <div className="knowledgeadd__table-container">
                      <table>
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {projectTimeline.map((val) => (
                            <tr key={val.id}>
                              <td className="knowledgeadd__layout-type">
                                <button className="knowledgeadd__delete-btn" onClick={() => removeProjectTimelineRow(val.id)}>
                                  ×
                                </button>
                                {/* {editingCell?.id === unit.id && editingCell?.field === "type" ? ( */}
                                <input
                                  type="text"
                                  value={val.title}
                                  onChange={(e) => handleProjectTimelineChange(val.id, "title", e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault(); // Mencegah default enter
                                      e.target.blur(); // Menghilangkan fokus dari input
                                    }
                                  }}
                                  className="knowledgeadd__input-text"
                                />
                                {/* ) : (
                                  <span onClick={() => tableHandleEdit(unit.id, "type")}>{unit.type || "Click to edit"}</span>
                                )} */}
                              </td>

                              <td>
                                <input
                                  type="text"
                                  className="knowledgeadd__input-text"
                                  value={val.description}
                                  onChange={(e) => handleProjectTimelineChange(projectTimeline.id, "description", e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault(); // Mencegah default enter
                                      e.target.blur(); // Menghilangkan fokus dari input
                                    }
                                  }}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="knowledgeadd__input-text"
                                  value={val.status}
                                  onChange={(e) => handleProjectTimelineChange(projectTimeline.id, "status", e.target.value)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault(); // Mencegah default enter
                                      e.target.blur(); // Menghilangkan fokus dari input
                                    }
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="knowledgeadd__bottom-bar">
                        <button type="button" className="knowledgeadd__add-btn" onClick={addProjectTimelineRow}>
                          +
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Save Buttons */}
              <div className="knowledgeadd__buttons">
                <button className="knowledgeadd__cancel" onClick={() => navigate("/knowledgebase")}>
                  Cancel
                </button>
                <button className="knowledgeadd__draft">Save to Draft</button>
                <button className="knowledgeadd__submit" disabled={loading}>
                  {loading ? "Saving..." : "Save & Submit"}
                </button>
              </div>
              <p> </p>
            </div>
          </div>
        )}
      </form>
      {/* Success Dialog */}
      {showSuccessDialog && (
        <div className="knowledgeadd__success-dialog">
          <p>Property saved successfully!</p>
          <button onClick={() => navigate("/knowledgebase")}>OK</button>
        </div>
      )}{" "}
    </div>
  );
};

export default KnowledgeDetail;
